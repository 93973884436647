import React from "react";

import { usePortalAuth } from "@/providers/keycloak-provider";
import { usePortalProvider } from "@/providers/portal-provider";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { useCallback, useMemo } from "react";
import { HeaderElementsAuthorizedMobile } from "./Mobile";
import { HeaderElementsAuthorizedDesktop } from "./Desktop";
import { deleteCookie } from "@/util/cookies";
import { ProfileLocalStorage } from "@/util/profile";

export const HeaderElementsAuthorized = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const { logout } = usePortalAuth();
  const { ssr, profile } = usePortalProvider();

  const onLogout = useCallback(() => {
    deleteCookie("kcIdToken");
    deleteCookie("kcToken");
    deleteCookie("kcUserInfo");
    localStorage.removeItem(ProfileLocalStorage);

    if (ssr) {
      logout();
    } else {
      keycloak?.logout();
    }
  }, [logout, keycloak, ssr]);

  const name = useMemo(() => {
    if (!profile?.name) {
      return;
    }
    const parts = (profile.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return profile.name;
  }, [profile?.name]);

  const onHandleRequest = useCallback((path: string) => {
    window.location.href = path;
  }, []);

  if (!profile) {
    return null;
  }

  return (
    <React.Fragment>
      <HeaderElementsAuthorizedMobile
        email={profile.email as string}
        name={name}
        onLogout={onLogout}
        onHandleRequest={onHandleRequest}
      />

      <HeaderElementsAuthorizedDesktop
        profile={profile}
        name={name}
        onHandleRequest={onHandleRequest}
        onLogout={onLogout}
      />
    </React.Fragment>
  );
};
